.user-info {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 20px;
}

.avatar-container {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 20px;
}

.avatar {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.info-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	text-align: center;
	margin-bottom: 20px;
	justify-items: start;
}

p {
	font-size: 16px;
	margin: 0.5rem;
}

h2 {
	font-size: 24px;
	margin-bottom: 10px;
}
