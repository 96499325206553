* {
  font-family: "Oswald", sans-serif;
}

/*
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*---------------------------------------------------------------------------------------------*/
#header {
  position: absolute;
  width: 220px;
}
#header .closemenu {
  color: rgb(161, 161, 161);
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  height: 100vh;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: transparent;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  height: 100vh;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: rgba(252, 252, 252, 0.521);
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: rgb(212, 212, 212);
  margin: 10px 0px;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #c0c0c0;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#header .logo {
  padding: 20px;
}
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
/*---------------------------------------Respponsives Cards-----------------------------------------------*/
:root {
  --cardWidth: 240px;
  --cardHeight: 240px;
  --cardMargin: 1px;
}
.main-section {
  /* max-width: 100%;
  margin: 0 auto; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* background: #bbb; */
  /* gap: 5px; */
  flex-direction: row;
  row-gap: 10px;
  column-gap: 10px;
}
.card {
  width: var(--cardWidth);
  height: var(--cardHeight);
  margin: var(--cardMargin);
  /* border: 1px solid #ccc;
  border-radius: 8px; */
  background: #fff;
  box-sizing: border-box;
}
/*---------------------------------------Another Respponsives Cards---------------------------------------*/
/* .wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  max-width: 300px;
  min-height: 250px;
  background: #02b875;
  padding: 30px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  margin: 20px;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px,
    rgb(0 0 0 / 30%) 0px 18px 36px -18px;
}
.card:nth-child(2) {
  background: #4181ee;
}
.card:last-child {
  background: #673ab7;
}
.card-title {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
}
.card-content {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
.card-btn {
  all: unset;
  display: block;
  margin-left: auto;
  border: 2px solid #fff;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 10px;
  font-weight: 600;
  transition: all 0.5s;
  cursor: pointer;
  letter-spacing: 1.2px;
}
.card-btn:hover {
  color: #02b875;
  background: #fff;
}
.card:nth-child(2) .card-btn:hover {
  color: #4181ee;
  background: #fff;
}
.card:last-child .card-btn:hover {
  color: #673ab7;
  background: #fff;
} */

.parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
}

.parent > div {
  padding: 5px;
  /* background: grey; */
}

.containerr {
  display: flex;
  flex-direction: row;
}
.itemm {
  display: flex;
  flex-shrink: 0;
}
.divider {
  display: flex;
  flex: 1;
}



.login-container {
	background: white;
	padding: 2rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.login-container h1 {
	margin-top: 0;
}

.github-button {
	background-color: #24292e;
	color: white;
	border: none;
	padding: 10px 15px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.github-button:hover {
	background-color: #3c4147;
}

.github-button img {
	vertical-align: middle;
	height: 24px;
	margin-right: 10px;
}
